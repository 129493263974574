import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nocountry',
  templateUrl: './nocountry.component.html',
  styleUrls: ['./nocountry.component.scss'],
})
export class NocountryComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
