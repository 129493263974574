import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  user: any;
  api_token;
  tokenFirebase;
  balance: 0;
  isLogged: boolean = false;
  constructor(private router: Router, private http: HttpClient) {}

  // Get user session
  async getSession() {
    // ...
    // put auth session here
    // ...

    // Sample only - remove this after real authentication / session

    return this.user;
    // return session;
  }
  async signintoken() {
    console.log('Login con token');
    return this.http.get(environment.apiUrl + '/auth/token').toPromise();
  }

  async getBalance() {
    return this.http.get(environment.apiUrl + '/users/getbalance').toPromise();
  }
  async getMyUser() {
    return this.http.get(environment.apiUrl + '/users/myuser').toPromise();
  }
  async getHistoryBalance() {
    return this.http
      .get(environment.apiUrl + '/users/gethistorybalance')
      .toPromise();
  }

  // Sign in
  async signIn(email: string, password: string) {
    // Sample only - remove this after real authentication / session

    return this.http
      .post(environment.apiUrl + '/auth/login', {
        email: email,
        password: password,
        fcmToken: this.tokenFirebase,
      })
      .toPromise();
  }

  // Sign up
  async signUp(form) {
    // Sample only - remove this after real authentication / session
    return this.http
      .post(environment.apiUrl + '/auth/sign-up-app', form)
      .toPromise();
  }
  uploadProfile(image) {
    return this.http
      .post(environment.apiUrl + '/user/picture', { image: image })
      .toPromise();
  }

  // Sign out
  async signOut() {
    // ...
    // clean subscriptions / local storage etc. here
    // ...

    // Navigate to sign-in
    this.isAuthenticated.next(false);
    this.isLogged = false;
    this.api_token = null;
    this.router.navigateByUrl('/signin');
  }

  sendCodigo(email) {
    return this.http
      .get(environment.apiUrl + '/users/recovery/' + email)
      .toPromise();
  }
  resetPassword(email, codigo, password) {
    return this.http
      .post(environment.apiUrl + '/users/changepassword', {
        email: email,
        codigo: codigo,
        password: password,
      })
      .toPromise();
  }

  getUsers(search) {
    return this.http
      .get(environment.apiUrl + '/user/getusers?email=' + search)
      .toPromise();
  }
  sendPush(email) {
    return this.http
      .post(environment.apiUrl + '/user/testpush', { email: email })
      .toPromise();
  }

  signupAdmin(email, password, name) {
    let params = {
      email: email,
      password: password,
      name: name,
    };
    return this.http
      .post(environment.apiUrl + '/auth/admin/sign-up-admin', params)
      .toPromise();
  }

  getUser(id) {
    return this.http.get(environment.apiUrl + '/user/' + id).toPromise();
  }

  updateUserData(user) {
    return this.http
      .post(environment.apiUrl + '/users/myuser', { user: user })
      .toPromise();
  }

  appleOrder(order) {
    return this.http.post(environment.apiUrl + '/payapple', order).toPromise();
  }
  settings() {
    return this.http.get(environment.apiUrl + '/users/getsettings').toPromise();
  }

  sendOTP() {
    return this.http.get(environment.apiUrl + '/users/otp').toPromise();
  }
  validarOTP(data) {
    return this.http.post(environment.apiUrl + '/users/otp', data).toPromise();
  }
  desvincularOTP() {
    return this.http.delete(environment.apiUrl + '/users/otp').toPromise();
  }
}
