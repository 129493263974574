import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import { HttpClient } from '@angular/common/http';
import { Observable, from, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  constructor(private http: HttpClient) {}

  getUserLocation(): Observable<any> {
    return from(Geolocation.getCurrentPosition()).pipe(
      switchMap((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        return this.getCountryFromCoordinates(latitude, longitude);
      }),
      catchError(() =>
        // Fallback a geolocalización por IP si falla el GPS
        this.getLocationByIP()
      )
    );
  }

  private getCountryFromCoordinates(
    lat: number,
    lon: number
  ): Observable<string> {
    // Usa un servicio de geocodificación inversa
    return this.http
      .get(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`
      )
      .pipe(
        map((response: any) => response.address.country),
        catchError(() => of('Unknown'))
      );
  }

  private getLocationByIP(): Observable<string> {
    return this.http.get('https://ipapi.co/json/').pipe(
      map((response: any) => response.country_name),
      catchError(() => of('Unknown'))
    );
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  isAllowedCountry(country: string): boolean {
    const blockedCountries = ['Venezuela', 'Unknown'];
    return !blockedCountries.includes(country);
  }
}
