import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { GeolocationService } from './services/gelocation.service';
import { environment } from 'src/environments/environment';
declare let Tawk_API: any;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  isLoading = true;
  isAllowed = false;
  constructor(
    private _authService: AuthService,
    private router: Router,
    private toast: ToastController,
    private geolocationService: GeolocationService
  ) {
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications

    PushNotifications.addListener('registration', (token: Token) => {
      //this.signin_form.controls['tokenFCM'].setValue(token.value)
      console.log('Ya lo registro');

      this._authService.tokenFirebase = token.value;

      //alert('Push registration success, token: ' + token.value);
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
      //alert('Error on registration: ' + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        //alert('Push received: ' + JSON.stringify(notification));

        if (notification.data.alert) {
          let myMessage: any = JSON.parse(notification.data.message);
          // if (parseInt(myMessage.sender)!=this.oUsers.id_user)'console
          //console.log("Creanto Toast");
          this.CreateToast(notification.data.alert);
        }
        if (notification.body) {
          // if (parseInt(myMessage.sender)!=this.oUsers.id_user)'console
          //console.log("Creanto Toast");
          this.CreateToast(notification.body);
        }
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        //alert('Push action performed: ' + JSON.stringify(notification));
      }
    );
  }
  async ngOnInit() {
    const isGeo = environment.isVenezuela;
    if (isGeo) {
      this.checkLocation();
    } else {
      this.router.navigateByUrl('/tabs/sportsbook');
    }

    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    try {
      Tawk_API.hideWidget();
    } catch (error) {}
  }
  checkLocation() {
    this.geolocationService.getUserLocation().subscribe(
      (country) => {
        console.log(country);
        this.isAllowed = this.geolocationService.isAllowedCountry(country);
        console.log(this.isAllowed);
        this.isLoading = false;
        if (!this.isAllowed) {
          this.router.navigateByUrl('/nocountry');
        }
      },
      (error) => {
        console.error('Error al obtener la ubicación:', error);
        this.router.navigateByUrl('/nocountry');
        this.isLoading = false;
        this.isAllowed = false; // O true, dependiendo de tu política por defecto
      }
    );
  }
  public async CreateToast(msg) {
    const toast = await this.toast.create({
      message: msg,
      duration: 4000,
      position: 'top',
      animated: true,
      color: 'primary',
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
            toast.dismiss();
          },
        },
      ],
    });
    toast.present();
  }
}
